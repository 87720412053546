import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Testimonials.css";

const Testimonials = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    }
    return (
        <div id="testimonials" className="standard-row service-area service-style-2 gray-bg grey-bg">
            <h2 className="text-center area-title">Testimonials</h2>
            <div className="container mt-4">
                <Slider {...settings} >
                    <div className="card-content">
                        <div className="card  col-lg-8 col-md-12 col-12 mx-auto">
                            <div className="card-body">
                                <div className="profile mb-4 mt-3 mx-auto w-25">
                                    <img src="./img/testimonials/sarinedekmak.png" className="rounded-circle mx-auto img-fluid" />
                                </div>
                                <div className="card-subtitle">
                                    <p>
                                        <small >
                                            <FontAwesomeIcon icon={faQuoteLeft} className="mr-2" />
                                    Luna, her name says it all!
                                    Brightens up your day with her amazing spirit and dedication! I've been working with her since Feb 2020 and all I can say about her that her dedication, talent and spirit are just epic! She never sleeps until she has delivered her work no matter how late it is, she works 24/7 and knows no weekends or limited working hours and her spirit is as sweet as a marshmallow!
                                    I'm blessed to have met her and can't wait to continue our journey together forever!
                                    <FontAwesomeIcon icon={faQuoteRight} className="ml-2" />
                                        </small>
                                    </p>
                                </div>
                                <small className="intro ">Sarine DEKMAK, co-founder of @dekmak.co</small>
                            </div>
                        </div>
                    </div>
                    <div card-content>
                        <div className="card  col-lg-8 col-md-12 col-12 mx-auto">
                            <div className="card-body">
                                <div className="profile mb-4 mt-3 mx-auto  w-25">
                                    <img src="./img/testimonials/armenurneshlian.png" className="rounded-circle mx-auto img-fluid" />
                                </div>
                                <div className="card-subtitle">
                                    <p>
                                        <small >
                                            <FontAwesomeIcon icon={faQuoteLeft} className="mr-2" />
                                        Working with Luna Hajjar at the Armenian Evangelical College was a positive experience. Although she cooperated with us for six months, she was always attentive and very punctual. She proved to be both a friendly and a professional person at the same time. The posters and the videos she made to promote the school and its activities were relevant, attractive and served their purpose.
                                    <FontAwesomeIcon icon={faQuoteRight} className="ml-2" />
                                        </small>
                                    </p>
                                </div>
                                <small className="intro ">Armen URNESHLIAN, Principle of Armenian Evangelical School</small>
                            </div>
                        </div>
                    </div>
                    <div card-content>
                        <div className="card col-lg-8 col-md-12 col-12 mx-auto">
                            <div className="card-body">
                                <div className="profile mb-4 mt-3 mx-auto  w-25">
                                    <img src="./img/testimonials/hectorhajjar.png" className="rounded-circle mx-auto img-fluid" />
                                </div>
                                <div className="card-subtitle">
                                    <p>
                                        <small >
                                            <FontAwesomeIcon icon={faQuoteLeft} className="mr-2" />
                                        Luna a travaillé dans la promotion de plusieurs événements dans l'Entreprise  surtout pendant la phase critique du Coronavirus. Elle a fait preuve de créativité, d’intelligence émotionnelle et a pu concrétiser les concepts d'une façon adaptée à la réalité. Elle a été considérée comme une valeur ajoutée dans la réussite de tous nos projets réalisés.
                                    <FontAwesomeIcon icon={faQuoteRight} className="ml-2" />
                                        </small>
                                    </p>
                                </div>
                                <small className="intro  text-bold">Hector HAJJAR, director of Message De Paix</small>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Testimonials;
