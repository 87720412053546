import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faAddressBook, faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import './Footer.css'

function Footer() {
    return (
        <>
            <div id="contact" className="footer-area text-left text-white" style={{ backgroundColor: '#4B245C' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="footer-fun-text">
                                <h3 className="footer-logo"><a href="#"><img src='./img/logo.png' className='img-fluid w-25'></img></a></h3>
                            </div>
                            <div className="social-bookmark-wrapper">
                                <ul className="social-bookmark pl-0">
                                    <li><a href="https://www.instagram.com/hajjarluna"><FontAwesomeIcon icon={faInstagram} size="2x" /></a></li>
                                    <li><a href="https://www.linkedin.com/in/lunahajjar"><FontAwesomeIcon icon={faLinkedin} size="2x" /></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-4 hidden-sm hidden-xs">
                            <aside className="instagram-widget">
                                <h4 className="widget-title">Contact Info</h4>
                                <ul className="list-unstyled">
                                    <li><FontAwesomeIcon icon={faEnvelope} className="mr-2"/> lunahajjar111@gmail.com</li>
                                    <li><FontAwesomeIcon icon={faMobileAlt}  className="mr-2 ml-1" /> +961 76 888 064</li>
                                </ul>
                                <p>Metn, Lebanon</p>
                            </aside>
                        </div>
                    </div>

                </div>
            </div>
            <footer className="copy-right-info pb-2 pt-2 text-white" style={{ backgroundColor: '#28003a' }}>
                <div className="container">
                    <small className="text-muted">All copyright &copy; reserved by Luna Hajjar 2020</small>
                </div>
            </footer>
        </>
    )
}

export default Footer;