import React from 'react';
import Navigation from './components/Navigation';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.css';
import "./About.css"

const About = () => {
    return (
        <>
            <Navigation />
            <div id="about-me" />
            <div className="container">
                <div className="mt-5 mb-5">
                    <div className="area-heading area-heading-style-two mb-4">
                        <h5 className="area-title">
                            ARE YOU LOOKING FOR A WAY TO COMMUNICATE YOUR IDEA WITH PEOPLE BUT DON’T KNOW HOW TO START? DO YOU WANT TO LAUNCH YOUR NEW IDEA AND REACH YOUR TARGET AUDIENCE IN THE MOST EFFICIENT, LEAST BORING CREATIVE WAY?
                        </h5>
                    </div>
                    <p className="mt-1">
                        There's no need to hide that people like to be entertained, in every way possible. Whether it’s an advertising video for your new product, a simple idea or maybe an official introduction to your company, its goal &amp; achievements, people STILL like to be entertained!
                    </p>
                    <p>
                        This is why I’m here to help, and make your life easier by being inspired by your idea, creating content &amp; finally making it alive and reachable for your targeted audience.
                    </p>
                    <h1 className="about-future">
                        <em>Let us create your future!</em>
                    </h1>
                    <p>
                        Hello, I’m Luna Hajjar!
                    </p>
                    <p>
                        Holder of a BA and MBA in 2D Animation from ALBA (L’Académie Libanaise des Beaux-Arts), Beirut, Lebanon, I am currently working as a freelancer since 2019.
                    </p>
                    <p>
                        People who work with me know how excited I get to see the final result of any project I work on.
                    </p>
                    <p>
                        Freelancing has taught me a lot about working under pressure, time management, communication with people, adapting and learning from my mistakes. And yes, that’s what I love most about my job!
                    </p>
                </div>
            </div>
            {/* <div className="standard-row process">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-4 col-12">
                            <div className="create">
                                <div className="blur"></div>
                                <p className="d-none">Creating the idea</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="build">
                                <div className="blur"></div>
                                <p className="d-none">Building the content</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12">
                            <div className="launch">
                                <div className="blur"></div>
                                <p className="d-none">Launching the concept</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}
            <Footer />
        </>
    )
}

export default About;