import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import SkeletonComponent from './components/SkeletonComponent';
import PortfolioLogos from './components/PortfolioLogos';
import PortfolioYoutube from './components/PortfolioYoutube';
import PortfolioMyProjects from './components/PortfolioMyProjects';
import PortfolioSculpture from './components/PortfolioSculpture';
import PortfolioPaintings from './components/PortfolioPaintings';
import './Portfolio.css'


function Portfolio() {
    const [Logos, setLogos] = useState("active");
    const [SocialMedia, setSocialMedia] = useState("");
    const [Youtube, setYoutube] = useState("");
    const [MyProjects, setMyProjects] = useState("");
    const [Sculptures, setSculptures] = useState("");
    const [Paintings, setPaintings] = useState("");
    const [Drawings, setDrawings] = useState("");
    const [ShowLoader, setShowLoader] = useState(false)
    const [SocialMediaLoadMore, setSocialMediaLoadMore] = useState(false);

    function LoadMore() {
        setSocialMediaLoadMore(true);
    }

    function filter(filter: string) {
        if (filter == "Logos") {
            setLogos("active");
            setSocialMedia("");
            setYoutube("");
            setMyProjects("");
            setSculptures("");
            setPaintings("");
            setDrawings("");
        }
        else if (filter == "SocialMedia") {
            setLogos("");
            setSocialMedia("active");
            setYoutube("");
            setMyProjects("");
            setSculptures("");
            setPaintings("");
            setDrawings("");
        }
        else if (filter == "Youtube") {
            setLogos("");
            setSocialMedia("");
            setYoutube("active");
            setMyProjects("");
            setSculptures("");
            setPaintings("");
            setDrawings("");
        }
        else if (filter == "MyProjects") {
            setLogos("");
            setSocialMedia("");
            setYoutube("");
            setMyProjects("active");
            setSculptures("");
            setPaintings("");
            setDrawings("");
        }
        else if (filter == "Sculptures") {
            setLogos("");
            setSocialMedia("");
            setYoutube("");
            setMyProjects("");
            setSculptures("active");
            setPaintings("");
            setDrawings("");
        }
        
        else if (filter == "Drawings") {
            setLogos("");
            setSocialMedia("");
            setYoutube("");
            setMyProjects("");
            setSculptures("");
            setDrawings("active");
            setPaintings("");

        }
        else if (filter == "Paintings") {
            setLogos("");
            setSocialMedia("");
            setYoutube("");
            setMyProjects("");
            setSculptures("");
            setDrawings("");
            setPaintings("active");
        }
        setSocialMediaLoadMore(false);
        setShowLoader(true);
        setTimeout(function () {
            setShowLoader(false)
        }, 2000)

    }

    return (

        <div id="portfolio" className="content standard-row bt-5 ">
            <div className="container">
                <div className="portfolio-content">
                    <div className="text-center">
                        <div className="area-heading area-heading-style-two text-center mb-4">
                            <h2 className="area-title">My Work</h2>
                        </div>
                        <ul className="portfolio-filter hover-style-one">
                            <li className={Logos}><a onClick={() => filter("Logos")}>Logos</a></li>
                            <li className={SocialMedia}><a onClick={() => filter("SocialMedia")}>Social Media</a></li>
                            <li className={Youtube}><a onClick={() => filter("Youtube")}>Youtube</a></li>
                            <li className={MyProjects}><a onClick={() => filter("MyProjects")}>My Projects</a></li>
                            <li className={Sculptures}><a onClick={() => filter("Sculptures")}>Sculptures</a></li>
                            <li className={Paintings}><a onClick={() => filter("Paintings")}>Paintings </a></li>
                            <li className={Drawings}><a onClick={() => filter("Drawings")}>Digital Drawings</a></li>
                        </ul>
                    </div>
                    <div className="portfolio portfolio-gutter portfolio-style-2 portfolio-masonry portfolio-column-count-3">
                        {/* <SpinnerRoundFilled color="#DBBB76" style={{ marginLeft: "auto", display: "block", marginRight: "auto" }} enabled={ShowLoader} size="150"></SpinnerRoundFilled> */}
                        {/* {!ShowLoader ? */}
                        <div>
                            {Logos == "active" ?
                                <PortfolioLogos />
                                : Youtube == "active" ?
                                    <PortfolioYoutube />
                                    : SocialMedia == "active" ?
                                        <div>
                                            <div className="row">
                                                <div className="col-md-4 col-6">
                                                    <div className="portfolio-item-content embed-logo-container">
                                                        <iframe src="https://player.vimeo.com/video/456156446" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                    </div>
                                                    <em className="text-center">
                                                        For @classcellulars via @dekmak.co
                                                    </em>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="portfolio-item-content  embed-logo-container">
                                                        <iframe src="https://player.vimeo.com/video/456284198" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                    </div>
                                                    <em className="text-center">
                                                        For @dekmak.co
                                                    </em>
                                                </div>
                                                {/* <div className="col-md-4 col-6">
                                                    <div className="portfolio-item-content  embed-logo-container">
                                                        <iframe src="https://player.vimeo.com/video/456169878" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-4 col-6">
                                                    <div className="portfolio-item-content  embed-logo-container">
                                                        <iframe src="https://player.vimeo.com/video/456177888" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                    </div>
                                                    <em className="text-center">
                                                        For @mortbenlebnene
                                                    </em>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="portfolio-item-content embed-logo-container">
                                                        <iframe src="https://player.vimeo.com/video/456182337" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                    </div>
                                                    <em className="text-center">
                                                        For @boutiqueperolladubai
                                                    </em>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="portfolio-item-content  embed-logo-container">
                                                        <iframe src="https://player.vimeo.com/video/456243396" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                    </div>
                                                    <em className="text-center">
                                                        For @dekmak.co
                                                    </em>
                                                </div>
                                                <div className="col-md-4 col-6">
                                                    <div className="portfolio-item-content  embed-logo-container">
                                                        <iframe src="https://player.vimeo.com/video/456197133" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                    </div>
                                                    <em className="text-center">
                                                        Giveaway video for @dekmak.co
                                                    </em>
                                                </div>
                                            </div>
                                            {SocialMediaLoadMore ?
                                                <div className="row">
                                                  
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456233905" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For Premiere Folding Doors and Windows via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456252059" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            Ramadan animation for @nutriuae via @dekmak.co
                                                        </em>
                                                    </div>
                                                   
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456257182" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            Ramadan animation for @kcateries via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456879146" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @kcateries via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456264113" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @timesaver.lb via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456273976" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @plexipro.sarl
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456288170" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            Wedding card
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456293022" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @tanyahamdan via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/456297032" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @solatohmecoaching via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/464768889" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @classcellulars via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/464777373" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @kidkit.lb
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576686085" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @wonderfulminds.uae via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576686848" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @drpawleb via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576681377" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @clickomart via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576687194" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @drpawleb via @dekmak.co
                                                        </em>
                                                    </div>
                                                    
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576687649" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @demak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576756984" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @demak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576754754" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @demak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576688005" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @demak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576689966" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @zencuisine__ via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576690233" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @zencuisine__ via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576690815" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @goodiesbeirut via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576691450" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @chocoloco.ro via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576692344" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @caremartleb via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/576753904" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @verdunclinic via @dekmak.co
                                                        </em>
                                                    </div>
                                                    <div className="col-md-4 col-6">
                                                        <div className="portfolio-item-content  embed-logo-container">
                                                            <iframe src="https://player.vimeo.com/video/579341938" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                                                        </div>
                                                        <em className="text-center">
                                                            For @609experience
                                                        </em>
                                                    </div>
                                                </div>
                                                : <div></div>}
                                            <div className="col-12">
                                                {!SocialMediaLoadMore ?
                                                    <button onClick={LoadMore}
                                                        style={{ background: "#7e349f", color: '#fff', border: 0, margin: "0 auto", display: "inherit", borderRadius: 10 }} className="p-2">LOAD MORE</button>
                                                    : <></>
                                                }
                                            </div>
                                        </div>
                                        : MyProjects == "active" ?
                                            <PortfolioMyProjects />
                                            : Sculptures == "active" ?
                                                <PortfolioSculpture />
                                                : Drawings == "active" ?
                                                    <div className="row">
                                                        <div className="col-md-12 ">
                                                            <SkeletonComponent src="./img/portfolio/digital-drawing/slide1.jpg" className="img-fluid"></SkeletonComponent>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <SkeletonComponent src="./img/portfolio/digital-drawing/slide2.jpg" className="img-fluid"></SkeletonComponent>
                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <SkeletonComponent src="./img/portfolio/digital-drawing/slide5.jpg" className="img-fluid"></SkeletonComponent>
                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <SkeletonComponent src="./img/portfolio/digital-drawing/slide7.jpg" className="img-fluid"></SkeletonComponent>
                                                        </div>
                                                        <div className="col-md-12 ">
                                                            <SkeletonComponent src="./img/portfolio/digital-drawing/slide8.jpg" className="img-fluid"></SkeletonComponent>
                                                        </div>
                                                        <div className="col-md-12 mt-4">
                                                            <SkeletonComponent src="./img/portfolio/digital-drawing/slide11.jpg" className="img-fluid"></SkeletonComponent>
                                                        </div>
                                                        <div className="col-md-12 mt-4 text-center">
                                                            <SkeletonComponent src="./img/portfolio/digital-drawing/drawingp.jpg" className="img-fluid"></SkeletonComponent>
                                                        </div>
                                                    </div>
                                                        : Paintings == "active" ?
                                                            <PortfolioPaintings />
                                                            : <div></div>}
                        </div>
                        {/* } */}

                        {/* <div className="pagination-area">
                                <div className="load-more text-center">
                                    <a className="button" href="#">Load More</a>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Portfolio;