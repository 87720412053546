import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Clients.css'
import { Animated } from "react-animated-css";


function Clients() {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            console.log(height);
            var height = document.getElementById("clients")?.offsetTop;
            if (height && window.scrollY > height - 800) {
                setAnimate(true);
            }
        });

        document.removeEventListener("scroll", () => { });
    }, [])


    return (
        <div id="clients">
            <Animated animationIn="fadeInLeft" className="container h-100" animationInDuration={2000} animationOut="fadeOut" isVisible={animate}>
                <div className="row h-100 my-auto mx-auto" style={{ justifyContent: 'center' }}>
                    <div className="col-md-2 col-4 text-white my-auto text-center">
                        <img src="./img/dek.png" className="img-fluid w-50  my-auto"></img>
                    </div>
                    <div className="col-md-2 col-4  text-white my-auto  text-center">
                        <img src="./img/aec.png" className="img-fluid w-50"></img>
                    </div>
                    <div className="col-md-2 col-4  text-white my-auto text-center">
                        <img src="./img/thumbnail_609.png" className="img-fluid w-50"></img>
                    </div>
                    <div className="col-md-2 col-4  text-white my-auto text-center">
                        <img src="./img/lea.png" className="img-fluid w-50"></img>
                    </div>
                    <div className="col-md-2 col-4  text-white my-auto text-center">
                        <img src="./img/thumbnail_shakhsiyeup.png" className="img-fluid w-50"></img>
                    </div>
                    <div className="col-md-2 col-4  text-white my-auto text-center">
                        <img src="./img/plexiii.png" className="img-fluid w-50"></img>
                    </div>
                </div>
            </Animated>
        </div>
    );
}

export default Clients;