import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './AboutMe.css'
import { Link } from 'react-router-dom';

function AboutMe() {
    return (
        <div id="about">
            <div className="container h-100">
                <div className="row h-100 my-auto">
                    <div className="offset-md-6 col-md-6 offset-4 col-8 text-white my-auto">
                        <div className="area-heading area-heading-style-two mb-4">
                            <h2 className="area-title">The best way to launch your new concept, is bring it to life!</h2>
                        </div>
                        <p>
                            I provide my clients with the fastest, cost-beneficial and premium quality service, by focusing on their needs and developing their ideas to initiate a well communicated concept.
                        </p>
                        <Link to="/about-me" className="explore-more" >EXPLORE MORE ABOUT ME</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;