import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTv, faDrawPolygon, faPlayCircle, faAddressCard, faHighlighter } from '@fortawesome/free-solid-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { Animated } from "react-animated-css";
import './WhatIDo.css'

function WhatIDo() {

    return (
        <div id="whatido" className="standard-row service-area service-style-2 gray-bg grey-bg">
            <div className="container">
                <div className="area-heading area-heading-style-two text-center mb-4 footer-fun-text">
                    <h3 className="area-title">What I do</h3>
                </div>
                <div className="row">
                    <Animated animationIn="fadeInLeft" animationInDuration={2000} className="col-lg-4 col-md-6 col-sm-12 mt-4 mt-md-0" animationOut="fadeOut" isVisible={true}>
                        <div className="single-service">
                            <div className="service-header">
                                <FontAwesomeIcon icon={faTv} className="service-icon" />
                                <h3>Advertising Videos</h3>
                            </div>
                            <p>Start advertising your products, services, or ideas in one of the most effective ways to reach out to customers.</p>
                        </div>
                    </Animated>
                    <Animated animationIn="fadeInLeft" animationInDuration={2000} className="col-lg-4 col-md-6 mt-4 mt-md-0" animationOut="fadeOut" isVisible={true}>
                        <div className="single-service">
                            <div className="service-header">
                                <FontAwesomeIcon icon={faDrawPolygon} className="service-icon" />
                                <h3>Logo Animation</h3>
                            </div>
                            <p>Turn your logo into a corporate identity in motion, a powerful advertising tool and eye catcher.</p>
                        </div>
                    </Animated>
                    <Animated animationIn="fadeInLeft" animationInDuration={2000} className="col-lg-4 col-md-6 mt-4 mt-lg-0" animationOut="fadeOut" isVisible={true}>
                        <div className="single-service">
                            <div className="service-header">
                                <FontAwesomeIcon icon={faYoutube} className="service-icon" />
                                <h3>Youtube Channel</h3>
                            </div>
                            <p>Customize your video by making it look more interesting, so you can increase the number of subscribers and likes.</p>
                        </div>
                    </Animated>
                    <Animated animationIn="bounceInRight" className="col-lg-4 col-md-6  mt-4" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
                        <div className="single-service">
                            <div className="service-header">
                                <FontAwesomeIcon icon={faPlayCircle} className="service-icon" />
                                <h3>Music Videos</h3>
                            </div>
                            <p>Let your animation take control over your music videos that are tailored just for you.</p>
                        </div>
                    </Animated>
                    <Animated animationIn="bounceInRight" className="col-lg-4 col-md-6  mt-4" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
                        <div className="single-service">
                            <div className="service-header">
                                <FontAwesomeIcon icon={faAddressCard} className="service-icon" />
                                <h3>Animated Cards</h3>
                            </div>
                            <p>Create your eco-friendly animated cards, in a cute and classy way.</p>
                        </div>
                    </Animated>
                    <Animated animationIn="bounceInRight" className="col-lg-4 col-md-6 mt-4" animationInDuration={2000} animationOut="fadeOut" isVisible={true}>
                        <div className="single-service">
                            <div className="service-header">
                                <img src="./img/highlight_icon.png" className="service-icon img-fluid" style={{ width: '35px' }} />
                                <h3>Highlight Covers</h3>
                            </div>
                            <p>Create branded highlight covers and keep the look-and-feel of your profile consistent with your platform aesthetic.</p>
                        </div>
                    </Animated>
                </div>
            </div>
        </div >
    );
}

export default WhatIDo;