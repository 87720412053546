import React, { createRef, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/esm/Navbar';
import Nav from 'react-bootstrap/esm/Nav';


function Navigation() {
    return (
        <header className="header-area header-style-one">
            <Navbar  collapseOnSelect expand="lg"  className="reverseBackground header-area">
                <div  className="container">
                <Navbar.Brand href="/"><img src="./img/logo.png" className="brand-logo"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="navbar-nav ml-auto main-menu">
                        <li>
                            <a href="/#whatido">What I Do</a> 
                        </li>
                        <li>
                        <a href="/#about">Luna</a> 
                        </li>
                        <li>
                        <a href="/#portfolio">Portfolio</a>
                        </li>
                        <li>
                        <a href="/#testimonials">Testimonials</a>
                        </li>
                        <li>
                        <a href="/#contact">Contact</a>
                        </li>
                    </Nav>
                </Navbar.Collapse>
                </div>
            </Navbar>
        </header >
    );
}
export default Navigation;