import React, { useEffect, useState } from 'react';
import './App.css';
import Layout from './Layout';
import Head from './Head';
import 'bootstrap/dist/css/bootstrap.css';
import { Routes } from './components/Router';
import Home from './Home';
import { HashRouter } from 'react-router-dom';
import ScrollTop from './components/ScrollTop';

function App() {
  const [isloading, setIsLoading] = useState(true);
  return (
    <>
      {isloading ?
        <>
          <img src="./img/loader.gif" style={{ width: '150px', marginTop: '40vh', marginLeft: "auto", display: "block", marginRight: "auto" }}></img>
          <img src='./img/logo-animation.gif' className='d-none' onLoad={() => setIsLoading(false)} />
        </>
        :
        <HashRouter >
          <ScrollTop/>
          <Routes />
        </HashRouter>
      }
    </>
  );
}

export default App;
