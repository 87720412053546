import React from "react"

const PortfolioMyProjects = () => {
    return (
        <div className="row">
            <div className="col-md-4  col-6">
                <div className="portfolio-item-content  embed-container">
                    <iframe src="https://player.vimeo.com/video/456951402" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Path of Light
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/339820669" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Turn Around
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/339822441" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Walk Cycle
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/339816945" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Mallow Game
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/339819370" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    La peur
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/456892106" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Lebanon
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/456888192" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    How To Kill a Mosquito
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/339824311" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Tarzan Poster
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/456890541" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Lebanon
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/463100406" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Unfinished 3D Project
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/463094785" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Unfinished 3D Project
            </em>
            </div>
            <div className="col-md-4 col-6">
                <div className="portfolio-item-content embed-container">
                    <iframe src="https://player.vimeo.com/video/464702220" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                </div>
                <em className="text-center">
                    Stop Motion projects with the Syrian refugees at Aakar
            </em>
            </div>
        </div>
    )
}

export default PortfolioMyProjects;