import React, { constructor, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

interface IProps {
    src: string
}

const SkeletonComponent = ({ ...props }) => {
    const [isLoading, setIsLoading] = useState("");
    const [imageClass,SetImageClass] = useState('img-fluid d-none');

    return (
        <>
            <Skeleton className={isLoading}>
            </Skeleton>
            <img src={props.src} className={imageClass} onLoadStart={() => setIsLoading("")} onLoad={() => {setIsLoading("d-none");SetImageClass('img-fluid')}}></img>
        </>
    )
}

export default SkeletonComponent