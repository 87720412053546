import React, { useEffect, useState } from 'react';
import './App.css';
import Layout from './Layout';
import Head from './Head';
import 'bootstrap/dist/css/bootstrap.css';
import WhatIDo from './WhatIDo';
import Portfolio from './Portfolio';
import Footer from './Footer';
import AboutMe from './AboutMe';
import Testimonials from './Testimonials';
import Clients from './Clients';

const Home =()=>{
    return(
        <div className="wrapper">
          <Layout></Layout>
          <Head></Head>
          <WhatIDo></WhatIDo>
          <AboutMe></AboutMe>
          <Portfolio></Portfolio>
          <Clients/>
          <Testimonials></Testimonials>
          <Footer></Footer>
        </div>
    )
}

export default Home;