import React from "react"


const PortfolioLogos = () => {

    const listOfIcons = [
        { url: "https://player.vimeo.com/video/455931211", title: "For @njeimlea" },
        { url: "https://player.vimeo.com/video/455924079", title: "For @faye_lawand via @dekmak.co" },
        { url: "https://player.vimeo.com/video/455930114", title: "For @solatohmecoaching via @dekmak.co" },
        { url: "https://player.vimeo.com/video/456126112", title: "For Premiere Folding Doors and Windows via @dekmak.co" },
        { url: "https://player.vimeo.com/video/456125129", title: "For @dekmak.co" },
        { url: "https://player.vimeo.com/video/456124173", title: "For @classcellulars via @dekmak.co" },
        { url: "https://player.vimeo.com/video/456121427", title: "For @layalfayad_sleepconsulting via @dekmak.co" },
        { url: "https://player.vimeo.com/video/472161493", title: "For @ritawakimhage" },
        { url: "https://player.vimeo.com/video/472159033", title: "For @upndownbeirut" },
        { url: "https://player.vimeo.com/video/472159290", title: "For @theconcept.furniture via @dekmak.co" },
        { url: "https://player.vimeo.com/video/472159125", title: "For @oniriq_paris via @609experience" },
        { url: "https://player.vimeo.com/video/576681922", title: "For @chordz.qavia @dekmak.co" },
        { url: "https://player.vimeo.com/video/576682414", title: "For @verdunclinic via @dekmak.co" },
        { url: "https://player.vimeo.com/video/495831903", title: "For @kyrakares via @njeimlea" },
        { url: "https://player.vimeo.com/video/576680557", title: "For @tandeeflb via @dekmak.co" },
        { url: "https://player.vimeo.com/video/576680306", title: "For @pawpots via @dekmak" },
        { url: "https://player.vimeo.com/video/576756677", title: "For @karen.simply via @dekmak.co" },
        { url: "https://player.vimeo.com/video/579342280", title: "For @609experience" },
    ]

    return (
        <div className="row">
            {listOfIcons.map(x =>
            (
                <div className="col-md-4 col-6">
                    <div className="portfolio-item-content embed-logo-container">
                        <iframe src={x.url} width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>
                    <em className="text-center">
                        {x.title}
                    </em>
                </div>
            )
            )}
        </div>
    )
}

export default PortfolioLogos;