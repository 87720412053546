import React from "react"
import SkeletonComponent from "./SkeletonComponent";

const PortfolioSculpture = () => {
    return (
        <div className="row">
            <div className="col-md-4 col-6 mt-4 m-lg-0">
                <SkeletonComponent src="./img/portfolio/sculpture3.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4 m-lg-0">
                <SkeletonComponent src="./img/portfolio/sculpture4.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4 m-lg-0">
                <SkeletonComponent src="./img/portfolio/sculpture5-c.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4">
                <SkeletonComponent src="./img/portfolio/sculpture1.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-8 mt-4">
                <SkeletonComponent src="./img/portfolio/sculpture2.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4 sixth-position" >
                <SkeletonComponent src="./img/portfolio/sculpture9.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4">
                <SkeletonComponent src="./img/portfolio/sculpture10.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4">
                <SkeletonComponent src="./img/portfolio/sculpture11.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4 ninthe-position" >
                <SkeletonComponent src="./img/portfolio/sculpture7.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4 tenth-position" >
                <SkeletonComponent src="./img/portfolio/sculpture8.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4 eleventh-position">
                <SkeletonComponent src="./img/portfolio/sculpture6.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4 twelveth-position" >
                <SkeletonComponent src="./img/portfolio/sculpture12.jpg" className="img-fluid"></SkeletonComponent>
            </div>
            <div className="col-md-4 col-6 mt-4 offset-md-4 thirteenth-position" >
                <SkeletonComponent src="./img/portfolio/sculpture13.jpg" className="img-fluid"></SkeletonComponent>
            </div>
        </div>
    )
}

export default PortfolioSculpture;