import React from "react"
import SkeletonComponent from "./SkeletonComponent";
import './PortfolioPaintings.css';

const PortfolioPaintings = () => {
    return (
        <>
            <div className="row">
                <div className="col-md-6 col-12">
                    <div className="row">
                        <div className="col-md-6 col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/1.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 col-12 mt-5 m-lg-0 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/5.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/3.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="row">
                        <div className="col-md-6 col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/2.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 col-12 mt-5 m-lg-0 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/4.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/6.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/16.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 col-12 mt-5 m-lg-0 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/17.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12 thrid-left">
                    <div className="row">
                        <div className="col-md-6 col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/11.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 col-12 mt-5 m-lg-0 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/12.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12 mx-auto mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/10.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 col-12 mt-5 m-lg-0 mx-auto mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/20.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-12 thrid-right">
                    <div className="row">
                        <div className="col-md-6 col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/18.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 col-12 mt-5 m-lg-0 mx-auto text-center">
                            <img src="./img/portfolio/drawing/19.jpg" className="img-fluid"></img>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/7.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 col-12 mt-5 m-lg-0 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/8.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-md-6 col-12 fourth-left">
                    <div className="row">
                        <div className="col-md-6 col-12 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/21.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 col-12 mt-5 m-lg-0 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/22.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 fourth-right">
                    <div className="row">
                        <div className="col-md-6 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/9.jpg" className="img-fluid"></SkeletonComponent>
                        </div>
                        <div className="col-md-6 mt-5 m-lg-0 mx-auto text-center">
                            <SkeletonComponent src="./img/portfolio/drawing/23.jpg" className="img-fluid"></SkeletonComponent>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioPaintings;