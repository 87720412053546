import React, { createRef, useEffect, useState } from 'react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from 'react-bootstrap/esm/Navbar';
import Nav from 'react-bootstrap/esm/Nav';
import { Link, animateScroll as scroll } from "react-scroll"
import './Layout.css';


function Layout() {
const [backgroundColor, setBackgroundColor] = useState("header-area header-style-one");

    useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 250) {
                setBackgroundColor("reverseBackground header-area");
            }
            else { setBackgroundColor("header-area header-style-one")};
        });
    
    })

    return (
        <header className="header-area header-style-one">
            <Navbar  collapseOnSelect expand="lg" fixed="top" className={backgroundColor}>
                <div  className="container">
                <Navbar.Brand href="/"><img src="./img/logo.png" className="brand-logo"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="navbar-nav ml-auto main-menu">
                        <li>
                            <Link style={{ cursor: 'pointer' }} to="whatido" spy={true} smooth={true}>What I Do</Link>
                        </li>
                        <li>
                            <Link style={{ cursor: 'pointer' }} to="about" spy={true} smooth={true}>Luna</Link>
                        </li>
                        <li>
                            <Link style={{ cursor: 'pointer' }} to="portfolio" spy={true} smooth={true}>Portfolio</Link>
                        </li>
                        <li>
                            <Link style={{ cursor: 'pointer' }} to="testimonials" spy={true} smooth={true}>testimonials</Link>
                        </li>
                        <li>
                            <Link style={{ cursor: 'pointer' }} to="contact" spy={true} smooth={true}>Contact</Link>
                        </li>
                    </Nav>
                </Navbar.Collapse>
                </div>
            </Navbar>
        </header >
    );
}
export default Layout;