import { Route, Switch } from 'react-router-dom';
import React from 'react';
import About from '../About';
import Home from '../Home';

export const Routes = () => (
    <Switch>
        <Route  path="/about-me" component={About}>
        </Route>
        <Route  path="/whatido" component={Home}></Route>
        <Route  path="/about" component={Home}></Route>
        <Route  path="/portfolio" component={Home}></Route>
        <Route  path="/testimonials" component={Home}></Route>
        <Route  path="/contact" component={Home}></Route>
        <Route exact  path="/" component={Home}>
        </Route>
    </Switch>
);