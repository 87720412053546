import React from "react"

const PortfolioYoutube = () => {
    return (
        <div className="row">
        <div className="col-md-4  col-6">
            <div className="portfolio-item-content  embed-container">
                <iframe src="https://player.vimeo.com/video/456872202" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
            <em className="text-center">
                Unreleased music video for @Laylakardan
            </em>
        </div>
        <div className="col-md-4 col-6">
            <div className="portfolio-item-content embed-container">
                <iframe src="https://player.vimeo.com/video/456129941" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
            <em className="text-center">
                Youtube Channel : Khallik Bl Beit
            </em>
        </div>
        <div className="col-md-4 col-6">
            <div className="portfolio-item-content embed-container">
                <iframe src="https://player.vimeo.com/video/456925858" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
            <em className="text-center">
                Youtube Channel : Faye Lawand
            </em>
        </div>
        <div className="col-md-4 col-6">
            <div className="portfolio-item-content embed-container">
                <iframe src="https://player.vimeo.com/video/456942766" width="640" height="564" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
            <em className="text-center">
                Youtube Channel : Khallik Bl Beit
            </em>
        </div>
    </div>
    )
}

export default PortfolioYoutube;